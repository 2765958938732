import React from 'react';
import ButtonAppBar from "./ButtonAppBar";

export const MainLayout = ({children}: any)=> {
    return (
        <div id={'mainLayout'}>
            <ButtonAppBar/>
            <div className={'children'}>{children}</div>
        </div>
    )
}
