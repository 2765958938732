import * as React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {firebaseConfig} from "../firebaseConfig";
import {getFirestore} from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import moment from 'moment'

type EventsType = {
    data: any;
    calendarData: any;
}

export const EventsContext = React.createContext<EventsType>({} as EventsType);

interface ContainerProps {
    children: any
}

const EventsContainer: React.FC<ContainerProps> = ({children}) => {
    const [data, setData] = React.useState<any>([]);
    const [calendarData, setCalendarData] = React.useState<any>([]);
    const app = firebase.initializeApp(firebaseConfig)
    const db = getFirestore(app);

    React.useEffect(() => {
        async function fetchEvents(){
            const snapshot = await firebase.firestore().collection('events/EventDetail/comp').get()

            if (!snapshot.empty) {
                let result = snapshot.docs.map((doc, index) => {
                    let temp: any = doc.data();
                    temp.id = index;
                    temp.date = moment(temp.date.toDate()).format("MM-DD-YYYY");
                    return temp
                });
                setData(result);
                let parsedData = result.map((event) => {
                    let temp = event;
                    temp.title = event.name;
                    temp.start = moment(event.date,"MM-DD-YYYY").toDate();
                    temp.end = moment(event.date,"MM-DD-YYYY").toDate();
                    return temp
                });
                setCalendarData(parsedData)
            } else {
                // docSnap.data() will be undefined in this case
                console.log("No such document!");
            }
        }
        fetchEvents();
    }, [])
    return (
        <EventsContext.Provider
        value={{data, calendarData}}
        >{children}
        </EventsContext.Provider>)
}

export default EventsContainer;
