import * as React from 'react';
import {styled} from "@mui/material/styles";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import InstagramIcon from '@mui/icons-material/Instagram';
import {useNavigate} from "react-router-dom";
import {ClickAwayListener} from "@mui/material";
import {useAuthenticator} from "@aws-amplify/ui-react";

const drawerWidth = 240;

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

interface Props {
    open: boolean;
    handleDrawerClose: () => void;
}

export const CustomDrawer = (props: Props) => {
    const {open, handleDrawerClose} = props;
    const navigate = useNavigate();
    const {user} = useAuthenticator((context) => [context.user]);
    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            //open={open}
            onClickAway={() => open && handleDrawerClose()}
        >
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        <CloseIcon/>
                    </IconButton>
                </DrawerHeader>
                {user ?
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                handleDrawerClose();
                                navigate('/profile')
                            }}>
                                <ListItemIcon>
                                    <PersonIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'Profile'}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <EmojiEventsIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'My Events'}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <UpcomingIcon/>
                                </ListItemIcon>
                                <ListItemText primary={'Applications'}/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    : null}
                <Divider/>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            handleDrawerClose();
                            navigate('/home')
                        }}>
                            <ListItemIcon>
                                <NewspaperIcon/>
                            </ListItemIcon>
                            <ListItemText primary={'News'}/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            handleDrawerClose();
                            navigate('/events')
                        }}>
                            <ListItemIcon>
                                <EventSeatIcon/>
                            </ListItemIcon>
                            <ListItemText primary={'All Events'}/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            handleDrawerClose();
                            navigate('/contactUs')
                        }}>
                            <ListItemIcon>
                                <MailIcon/>
                            </ListItemIcon>
                            <ListItemText primary={'Contact Us'}/>
                        </ListItemButton>
                    </ListItem>
                </List>
                <div
                    style={{marginLeft: 'auto', marginRight: 'auto', marginTop: 'auto', marginBottom: 2}}
                    className={'socialMediaIcon'} onClick={() => {
                    window.open('https://www.instagram.com/')
                }}>
                    <InstagramIcon fontSize="large"/>
                </div>
            </Drawer>
        </ClickAwayListener>
    )
}