import React from 'react';
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {HomeCalendar} from "../Home/homeCalendar";

export const AllEventsOverview = () => {
    return (
        <Box>
            <Box sx={{background: 'grey'}} pt={2} pb={2}><Typography variant={'h4'} sx={{color: 'white'}} pl={4}>All
                Events</Typography></Box>
            <Box p={2}>
                <HomeCalendar/>
            </Box>
        </Box>
    )
}