import * as React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import {EventsContext} from "../../context/EventsContext";
const localizer = momentLocalizer(moment);

export const HomeCalendar = () => {
    const {calendarData} = React.useContext(EventsContext);
    const myEventsList: any[] = calendarData;
    console.log(myEventsList)
    return (
        <div>
            <Calendar
                localizer={localizer}
                events={myEventsList}
                startAccessor="start"
                endAccessor="end"
                style={{height: 500}}
            />
        </div>
    )
}