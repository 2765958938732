import React from 'react';
import {createBrowserRouter, Navigate,} from "react-router-dom";
import {MainLayout} from "../Layout/mainLayout";
import {Home} from "./Home/home";
import {ContactUsView} from "./ContactUs/ContactUsView";
import {EventOverview} from "./Event/eventOverview";
import {AllEventsOverview} from "./Events/allEventsOverview";
import {ProfileView} from "./Profile/profileView";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to="/home" replace />,

    },
    {
        path: "/home",
        element: <MainLayout><Home /></MainLayout>,

    },
    {
        path: "/profile",
        element: <MainLayout><ProfileView /></MainLayout>,

    },
    {
        path: "/events",
        element: <MainLayout><AllEventsOverview /></MainLayout>,

    },
    {
        path: "/events/:eventId",
        element: <MainLayout><EventOverview /></MainLayout>,

    },
    {
        path: "/contactUs",
        element: <MainLayout><ContactUsView /></MainLayout>,

    },

]);