import * as React from 'react';
import {Flex, Input, Label, PhoneNumberField, useAuthenticator} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import {getUserProfileForm, UserProfileType} from "../../Types/UserProfileType";
import {
    updateUserAttributes,
    updateUserAttribute,
    type UpdateUserAttributeOutput,
    type UpdateUserAttributesOutput
} from 'aws-amplify/auth';
import {UserProfileContext} from "../../context/UserProfileContext";

async function handleUpdateUserAttributes(form: any) {
    try {
        const output = await updateUserAttributes({
            userAttributes: {
                'custom:chineseName':  form.chineseName || '',
                'custom:displayName':  form.displayName || '',
                'custom:firstName':  form.firstName || '',
                'custom:lastName':  form.lastName || '',
                'custom:gender':  form.gender || '',
                'custom:hkId':  form.hkId || '',
                'custom:dialCode':  form.dialCode || '',
                'custom:phoneNumber':  form.phoneNumber || '',
                'custom:dateOfBirth':   form.dateOfBirth || '',
            },
        });
        handleUpdateUserAttributesNextSteps(output);
    } catch (error) {
        console.log(error);
    }
}

function handleUpdateUserAttributesNextSteps(output:UpdateUserAttributesOutput) {
    console.log(`attribute was successfully updated.`);
}


export const ProfileView = () => {
    const navigate = useNavigate();
    const {loginUser, attributes} = React.useContext(UserProfileContext);
    const [userProfileForm, setUserProfileForm] = React.useState<UserProfileType>(getUserProfileForm(attributes));


    const submitForm = () => {
        handleUpdateUserAttributes(userProfileForm);
    }
    return attributes?(
        <Box>
            <Box sx={{background: 'grey'}} pt={2} pb={2}><Typography variant={'h4'} sx={{color: 'white'}}
                                                                     pl={4}>Profile</Typography></Box>
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControl sx={{width: {xs: '100%', lg: 'auto'}}}>
                            <FormLabel id="demo-radio-buttons-group-label">Chinese Name(if any)</FormLabel>
                            <TextField
                                id="chineseName"
                                value={userProfileForm.chineseName}
                                onChange={(e) => setUserProfileForm({...userProfileForm, chineseName: e.target.value})}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControl sx={{width: {xs: '100%', lg: 'auto'}}}>
                            <FormLabel id="demo-radio-buttons-group-label">First Name</FormLabel>
                            <TextField
                                required
                                id="firstName"
                                value={userProfileForm.firstName}
                                onChange={(e) => setUserProfileForm({...userProfileForm, firstName: e.target.value})}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControl sx={{width: {xs: '100%', lg: 'auto'}}}>
                            <FormLabel id="demo-radio-buttons-group-label">Last Name</FormLabel>
                            <TextField
                                required
                                id="lastName"
                                value={userProfileForm.lastName}
                                onChange={(e) => setUserProfileForm({...userProfileForm, lastName: e.target.value})}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControl sx={{width: {xs: '100%', lg: 'auto'}}}>
                            <FormLabel id="demo-radio-buttons-group-label">Display Name</FormLabel>
                            <TextField
                                required
                                id="displayName"
                                value={userProfileForm.displayName}
                                onChange={(e) => setUserProfileForm({...userProfileForm, displayName: e.target.value})}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={userProfileForm.gender}
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="F" control={<Radio/>} label="Female"/>
                                <FormControlLabel value="M" control={<Radio/>} label="Male"/>
                                <FormControlLabel value="N" control={<Radio/>} label="Other"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <PhoneNumberField
                            defaultDialCode="+852"
                            label="Phone number"
                            descriptiveText="Please enter your phone number"
                            placeholder="21800000"
                            onDialCodeChange={(e) => {
                                setUserProfileForm({...userProfileForm, dialCode: e.target.value})
                            }}
                            value={userProfileForm.phoneNumber}
                            onChange={(e) => setUserProfileForm({...userProfileForm, phoneNumber: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Flex direction="column" gap="small">
                            <Label htmlFor="departing">Birthday️</Label>
                            <Input
                                id="dateOfBirth"
                                type="date"
                                value={userProfileForm.dateOfBirth}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    setUserProfileForm({...userProfileForm, dateOfBirth: e.target.value})
                                }}
                            />
                        </Flex>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControl sx={{width: {xs: '100%', lg: 'auto'}}}>
                            <FormLabel id="demo-radio-buttons-group-label">HK ID first 4 characters(if only)</FormLabel>
                            <TextField
                                id="hkID"
                                value={userProfileForm.hkId}
                                onChange={(e) => setUserProfileForm({...userProfileForm, hkId: e.target.value})}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Box display={'flex'} justifyContent={'flex-end'} mt={2}><Button variant="contained" onClick={()=>{submitForm()}}>Save</Button></Box>
            </Box>
        </Box>
    ): <CircularProgress />
}