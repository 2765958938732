import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import {AppBarProps} from "@mui/material";
import {CustomDrawer} from "./CustomDrawer";
import {AuthDialog} from "../scenes/CustomAuthenticator/AuthDialog";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";

interface StyledAppBarProps extends AppBarProps {
    open?: boolean;
}

const StyledAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<StyledAppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        //width: `calc(100% - ${drawerWidth}px)`,
        //marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function ButtonAppBar() {
    const [open, setOpen] = React.useState(false);
    const [loginOpen, setLoginOpen] = React.useState(false);
    const navigate = useNavigate();
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AuthDialog open={loginOpen} onClose={()=>setLoginOpen(false)}/>
            <StyledAppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        sx={{ mr: 2}}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={()=>navigate('/home')}>
                        CLIMBFUNITY:)
                    </Typography>
                    {user? <Button color="inherit" onClick={()=> {
                        navigate('/home');
                        signOut();
                    }}>Logout</Button>: <Button color="inherit" onClick={()=>setLoginOpen(true)}>Login</Button>}
                </Toolbar>
            </StyledAppBar>
            <CustomDrawer open={open} handleDrawerClose={handleDrawerClose}/>
        </Box>
    );
}