import {fetchUserAttributes, getCurrentUser} from "aws-amplify/auth";
import {createContext} from "react";
import * as React from 'react';
import {Hub} from 'aws-amplify/utils';
import {useAuthenticator} from "@aws-amplify/ui-react";

// const currentUser = await getCurrentUser();
// console.log("currentUser", currentUser); // this logs an object with a userId and username.   No attributes are included.
// const attributes = await fetchUserAttributes(); // <-- This line throws an error
// console.log("attributes", attributes);

type UserProfileType = {
    loginUser: any;
    attributes: any;
}

export const UserProfileContext = createContext<UserProfileType>(
    {} as UserProfileType
);

interface ContainerProps {
    children: any
}

const UserProfileContainer: React.FC<ContainerProps> = ({children}) => {
    const [loginUser, setLoginUser] = React.useState<any>(null);
    const [attributes, setAttributes] = React.useState<any>(null);
    const [loading, setLoading] = React.useState(true);
    const {user} = useAuthenticator((context) => [context.user]);
    React.useEffect(() => {
        async function fetchUser() {
            const currentUser = await getCurrentUser();
            setLoginUser(currentUser);
            const attributes = await fetchUserAttributes(); // <-- This line throws an error
            setAttributes(attributes);
        }
        if(user && !loginUser) fetchUser();
    }, [user])
    Hub.listen('auth', async (event: any) => {
        console.log(event)
        if (event.payload.event === 'signIn' || event.payload.event === "signInWithRedirect") {
            console.log("signIn", event)
            setLoginUser(event.payload.data);
            const currentUser = await getCurrentUser();
            console.log("currentUser", currentUser); // this logs an object with a userId and username.   No attributes are included.
            const attributes = await fetchUserAttributes(); // <-- This line throws an error
            console.log("attributes", attributes);
            setAttributes(attributes);
            setLoading(false);
        } else if (event.payload.event === 'signOut') {
            setLoginUser(null);
            setAttributes(null)
        }
    });

    return (
        <UserProfileContext.Provider
            value={{
                loginUser,
                attributes
            }}
        >{children}
        </UserProfileContext.Provider>
    )
}
export default UserProfileContainer;