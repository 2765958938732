import React from 'react';
import Box from "@mui/material/Box";
import {Card, CardActions, CardContent, CardMedia, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {HomeCalendar} from "./homeCalendar";

const data = [
    {
        src: 'https://plus.unsplash.com/premium_photo-1661888350177-48a9efbf6985?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y2xpbWJpbmd8ZW58MHx8MHx8fDA%3D',
        title: 'ClimbFunity Cup 2024 - Lead 難度攀登',
        location: 'HK',
        date: '08-17-2024',
    },
    {
        src: 'https://media.istockphoto.com/id/480310380/photo/climber-woman-standing-in-front-of-a-stone-rock-outdoor.jpg?s=612x612&w=0&k=20&c=XRpMmbU-5QPFpJHODXwr8IDjWXfLmwQYm6-jQccggyw=',
        title: 'dummy',
        location: 'HK',
        date: 'No Idea',
    },
    {
        src: 'https://media.istockphoto.com/id/480310380/photo/climber-woman-standing-in-front-of-a-stone-rock-outdoor.jpg?s=612x612&w=0&k=20&c=XRpMmbU-5QPFpJHODXwr8IDjWXfLmwQYm6-jQccggyw=',
        title: 'dummy',
        location: 'HK',
        date: 'No Idea',
    }
];

export const Home = () => {
    const navigate = useNavigate();
    return (
        <Box>
            <Box sx={{background:'grey'}}  pt={2} pb={2}><Typography variant={'h4'} sx={{color: 'white'}} pl={4}>Latest News</Typography></Box>
            <Box p={2}>
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    {data.map((item, index) => {
                            return (
                                <Grid key={`home_grid_${index}`} item xs={12} md={4} onClick={()=>{navigate(`/events/${index}`)}}  className={'socialMediaIcon'}>
                                    <Card>
                                        <CardMedia
                                            sx={{height: 400}}
                                            image={item.src}
                                            title={item.title}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {item.title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Location: {item.location}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Date: {item.date}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        }
                    )}
                </Grid>
            </Box>
        </Box>
    )
}