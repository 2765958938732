import React from 'react';
import {Dialog} from "@mui/material";
import {Authenticator} from "@aws-amplify/ui-react";

interface DialogProps{
    open: boolean;
    onClose: ()=>void;

}

export const AuthDialog = (props: DialogProps)=>{
    const {open, onClose} = props;
    return (
        <Dialog
            open={open}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <Authenticator socialProviders={['google']}/>
        </Dialog>
    )
}