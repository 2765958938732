import React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Fab, Stack, Tooltip} from "@mui/material";
import NavigationIcon from '@mui/icons-material/Navigation';
import {CustomStackItem} from "../../components/customStackItem";
import IconButton from "@mui/material/IconButton";
import InfoIcon from '@mui/icons-material/Info';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export const EventOverview = () => {
    return (
        <Box sx={{display: 'flex'}} justifyContent={'center'} flexDirection={'column'} alignItems={'center'} p={2}>
            <Box width={'fit-content'}>
                <Typography variant={'h4'} mb={2} pt={2}>ClimbFunity Cup 2024 - Lead 難度攀登</Typography>
                <Typography variant={'body1'}>Location: </Typography>
                <Typography variant={'body1'}>Date: </Typography>
                <Box sx={{
                    backgroundImage: `url("https://plus.unsplash.com/premium_photo-1661888350177-48a9efbf6985?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y2xpbWJpbmd8ZW58MHx8MHx8fDA%3D")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    height: "385px",
                }}/>
            </Box>
            <Box width={'fit-content'}>
                <Typography variant={'h6'} mt={2}>Event Detail: </Typography>
                <Typography variant={'body1'} mt={2}>Format: </Typography>
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <CustomStackItem>初級組 - 頂繩攀登 Amateur - Top Rope
                        <Tooltip title="1. 未曾於國際運動攀登總會(IFSC)或中國香港攀山及攀登總會 (CHKMCU)認可或舉辦/合辦之任何難度賽事 (包括頂繩及領攀，任何參賽組別)中獲取前三名位置
2. 未曾任運動攀登的國家或香港代表隊隊員">
                            <IconButton sx={{padding: '0'}}>
                                <InfoIcon/>
                            </IconButton>
                        </Tooltip>
                    </CustomStackItem>
                    <CustomStackItem>高級組 - 領攀 Advance - Lead
                        <Tooltip title="在賽前有領攀經驗以策安全">
                            <IconButton sx={{padding: '0'}}>
                                <InfoIcon/>
                            </IconButton>
                        </Tooltip>
                    </CustomStackItem>
                </Stack>
                <Typography variant={'body1'} mt={2}>Categories: </Typography>
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <CustomStackItem>Junior (Top Rope Only)</CustomStackItem>
                    <CustomStackItem>大專</CustomStackItem>
                    <CustomStackItem>Open</CustomStackItem>
                </Stack>
                <Typography variant={'body1'} mt={2}>Fee: </Typography>
                <Typography variant={'body2'}>$250</Typography>
                <Typography variant={'body1'} mt={2}>Practice Session: </Typography>
                <Typography variant={'body2'}>$100 preorder | $160 walk-in</Typography>
                <Typography variant={'body1'} mt={2}>Qutoa: </Typography>
                <Typography variant={'body2'}>20 per group</Typography>
                <Typography variant={'body1'} mt={2}>Rules: </Typography>
                <Typography variant={'body2'}>初賽：開放形式 (不設隔離區，設示範)</Typography>
                <Typography variant={'body2'}>決賽：隔離形式 (設隔離區，不設示範)</Typography>
                <Typography variant={'body2'}>各組別攀爬2條比賽路綫決定初賽成績，成績頭8名進入決賽，以1條比賽路綫決定總成績，如有相同成績，追溯初賽成績</Typography>
                <Typography variant={'body2'} mt={2}>Information: <IconButton><AttachFileIcon/></IconButton></Typography>
            </Box>
            <Fab variant="extended" color="success" sx={{position: 'fixed', right: '20px', bottom: '50px'}}>
                <NavigationIcon sx={{ mr: 1 }} />
                Apply
            </Fab>
        </Box>)
}