import React from 'react';
import './App.css';
import {router} from "./scenes/main";
import {RouterProvider} from "react-router-dom";
import {ThemeProvider} from '@mui/material/styles';
import {theme} from "./styles/theme";
import {Amplify} from "aws-amplify";
import config from './aws-exports'
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import UserProfileContainer from "./context/UserProfileContext";
import EventsContainer from "./context/EventsContext";


Amplify.configure(config);

function App() {
    return (
        <Authenticator.Provider>
            {/*<Authenticator socialProviders={['google']}>*/}
            <ThemeProvider theme={theme}>
                <UserProfileContainer>
                    <EventsContainer>
                        <RouterProvider router={router}/>
                    </EventsContainer>
                </UserProfileContainer>
            </ThemeProvider>
            {/*</Authenticator>*/}
        </Authenticator.Provider>
    );
}

export default App;
