import {createTheme} from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontFamily: ['Manrope', 'sans-serif'].join(','),
        fontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 'bold',
        // h1:{
        //     fontSize: 14,
        //     fontWeight: 600,
        //     lineHeight: '24px',
        //     color: '#656565',
        // },
        // h2: {
        //     fontSize: 32,
        //     fontWeight: 700,
        //     lineHeight: '40px',
        //     color: '#434343',
        // },
        // h3:{
        //     fontSize: 20,
        //     fontWeight: 700,
        //     lineHeight: '28px',
        //     color: '#434343',
        // },
        // h4:{
        //     fontSize: 16,
        //     fontWeight: 700,
        //     lineHeight: '24px',
        //     color: '#434343',
        // },
        // h5:{
        //     fontSize: 14,
        //     fontWeight: 700,
        //     lineHeight: '24px',
        //     color: '#434343',
        // },
        // h6:{
        //     fontSize: 12,
        //     fontWeight: 600,
        //     lineHeight: '20px',
        //     color: '#888888',
        // },
        // subtitle1:{
        //     fontSize: 14,
        //     fontWeight: 500,
        //     lineHeight: '24px',
        //     color: '#656565',
        // },
        // subtitle2:{
        //     fontSize: 12,
        //     fontWeight: 700,
        //     lineHeight: '16px',
        //     color: '#888888',
        // },
        // body1:{
        //     fontSize: 12,
        //     fontWeight: 600,
        //     lineHeight: '20px',
        //     color: '#656565',
        // },
        // body2:{
        //     fontSize: 12,
        //     fontWeight: 700,
        //     lineHeight: '16px',
        //     color: '#0C77D8',
        // },
    },
    palette: {
        primary: {
            main: '#0C77D8',
            light: '#EDF6FF',
            dark: '#0761B3',
            contrastText: '#FFF',
        },
        secondary: {
            main: '#D1D1D1',
            light: '#EFEFEF',
            dark: '#434343',
            contrastText: '#434343',
        },
        text: {
            primary: 'rgba(67, 67, 67, 100)',
            secondary: 'rgba(101, 101, 101, 100)',
            disabled: 'rgba(209, 209, 209, 100)',
        },
        grey: {
            '50': '#F8F8F8',
            '100': '#EFEFEF',
            '200': '#E5E5E5',
            '300': '#D1D1D1',
            '400': '#ABABAB',
            '500': '#888888',
            '600': '#656565',
            '700': '#434343',
        },
        info: {
            light: '#EDF6FF',
            main: '#0C77D8',
            dark: '#0761B3',
            contrastText: '#FFFFFF',
        },
        success: {
            light: '#E8F6F2',
            main: '#008479',
            dark: '#105751',
            contrastText: '#fff',
        },
        warning: {
            light: '#FCF3E5',
            main: '#D46829',
            dark: '#913C00',
            contrastText: '#fff',
        },
        error: {
            light: '#FFEAEB',
            main: '#B7373E',
            dark: '#A02C32',
            contrastText: '#fff',
        },
        background: {
            default: '#FFFFFF',
            paper: '#FFFFFF',
        },
    },


});