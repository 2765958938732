export interface UserProfileType {
    chineseName: string;
    firstName: string;
    lastName: string;
    displayName: string;
    gender: 'F' | 'M' | 'N';
    phoneNumber: string;
    dialCode: string;
    dateOfBirth: string;
    hkId: string;
}

export function getUserProfileForm(attributes?: any):UserProfileType{
    return {
        chineseName: attributes?attributes['custom:chineseName'] : '',
        firstName: attributes?attributes['custom:firstName'] : '',
        lastName: attributes?attributes['custom:lastName'] : '',
        displayName: attributes?attributes['custom:displayName'] : '',
        gender: attributes?attributes['custom:gender'] : '',
        phoneNumber: attributes?attributes['custom:phoneNumber'] : '',
        dateOfBirth: attributes?attributes['custom:dateOfBirth'] : '',
        hkId: attributes?attributes['custom:hkId'] : '',
        dialCode: attributes?attributes['custom:dialCode'] : '',
    }
}

